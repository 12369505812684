import React from 'react';
import styled from 'styled-components';
import { colors } from 'variables';

const Wrapper = styled.div`
  display: flex;
`;

const IdCell = styled.div`
  flex: 0 0 52px;
  padding-bottom: 4px;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const ProjectCell = styled.div`
  flex: 0 0 380px;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const CategoryCell = styled.div`
  flex: 0 0 128px;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const DEAScoreCell = styled.div`
  flex: 0 0 133px;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const DEAwardCell = styled.div`
  flex: 0 0 178px;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const CommentCell = styled.div`
  flex: 0 0 108px;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const PublishedCell = styled.div`
  flex: 0 0 153px;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const VotesCell = styled.div`
  flex: 0 0 55px;
  display: flex;
  justify-content: center;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const TotalCell = styled.div`
  flex: 0 0 75px;
  display: flex;
  justify-content: center;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

const AverageCell = styled.div`
  flex: 0 0 135px;
  display: flex;
  justify-content: flex-end;
  color: ${colors.secondary};
  border-bottom: 2px solid ${colors.secondary};
`;

export const TableHeader: React.FC = () => (
  <Wrapper>
    <IdCell>No</IdCell>
    <ProjectCell>Project</ProjectCell>
    <CategoryCell>Category</CategoryCell>
    <DEAScoreCell>DEA Score</DEAScoreCell>
    <PublishedCell>Published</PublishedCell>
    <DEAwardCell>DEA Label</DEAwardCell>
    <CommentCell>Comment</CommentCell>
    <VotesCell>Votes</VotesCell>
    <TotalCell>Total</TotalCell>
    <AverageCell>Average</AverageCell>
  </Wrapper>
);
