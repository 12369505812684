import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';

import { apiUrls, colors } from 'variables';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { apiClient } from 'helpers/APIClient';
import { Loader } from 'components/common/Loader';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 1400px;
    max-height: 90%;
    padding: 16px;
    padding-top: 8px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: ${colors.background};
    color: ${colors.secondary};
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;
    overflow-y: scroll;
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 4px;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  margin-left: auto;
  background-color: transparent;
  color: ${colors.secondary};
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const Name = styled.p`
  color: white;
`;
const Comment = styled.p`
  margin-top: 0;
  margin-bottom: 24px;
`;

interface Props {
  closeModal: () => void;
  projectId: number | null;
}

export const CommentsModal: React.FC<Props> = ({ closeModal, projectId }) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [comments, setComments] = React.useState<{ fullName: string; comment: string }[]>([]);

  React.useEffect(() => {
    if (!projectId) return;
    setComments([]);

    setIsFetching(true);

    try {
      apiClient.get(apiUrls.projectComments(projectId)).then(({ data }) => setComments(data));
    } catch {
      toast('Failed to fetch comments', toastSettings);
    } finally {
      setIsFetching(false);
    }
  }, [projectId]);

  return (
    <Modal isOpen={!!projectId} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
      <ModalStyles />
      {isFetching || !comments.length ? (
        <Loader />
      ) : (
        <>
          <Header>
            <CloseButton onClick={closeModal}>X</CloseButton>
          </Header>

          {comments.map(comment => (
            <React.Fragment key={comment.fullName}>
              <Name>{comment.fullName}</Name>
              <Comment>{comment.comment}</Comment>
            </React.Fragment>
          ))}
        </>
      )}
    </Modal>
  );
};
