import React from 'react';
import styled from 'styled-components';
import Rate from 'rc-rate';
import 'rc-rate/assets/index.css';

const Wrapper = styled.div`
  flex: 0 0 135px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 1.2rem;
`;

const StyledRate = styled(Rate)<{ size: number }>`
  &.rc-rate {
    font-size: ${({ size }) => size}px !important;
    margin-right: -8px;
    margin-top: -8px;
  }
`;

interface Props {
  score: number | null;
}

export const AverageCell: React.FC<Props> = ({ score }) => (
  <Wrapper>
    <span>{score ? (Math.round(score * 10) / 10).toFixed(1).toString().padEnd(3, '0') : '0.0'}</span>
    <StyledRate value={score ? Math.floor(score) : 0} disabled size={24} />
  </Wrapper>
);
