import React from 'react';
import styled from 'styled-components';

import { colors } from 'variables';
import { Checkbox } from './Checkbox';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorMessage = styled.p`
  margin: 0 0 30px;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

interface CheckboxProps {
  label: string;
  value: string;
}

interface Props {
  checkboxes: CheckboxProps[];
  error?: string;
  name: string;
  touched?: boolean;
  onChange: (e: React.ChangeEvent<unknown>) => void;
}

export const CheckboxGroup: React.FC<Props> = ({ checkboxes, error, name, onChange, touched }) => (
  <Wrapper role="group">
    {checkboxes.map(checkbox => (
      <Checkbox
        key={checkbox.value}
        label={checkbox.label}
        name={name}
        onChange={onChange}
        value={checkbox.value}
      />
    ))}
    {touched && error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
);
