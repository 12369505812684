import axios from 'axios';
import Cookies from 'js-cookie';

import { removeTokens, setAccessToken } from 'contexts/AuthContext';
import { history } from 'routerHistory';
import { apiUrls, routes } from 'variables';
import { toast } from 'react-toastify';
import { toastSettings } from './toastSettings';

const apiClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

const noTokenUrls = [apiUrls.login];

apiClient.interceptors.request.use(
  config => {
    if (noTokenUrls.some(url => config.url === url)) {
      return config;
    }

    const token = Cookies.get('accessToken');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const {
        response: { status },
      } = error;
      if (
        status === 401 &&
        error.response?.data?.errors?.some((err: { message?: string }) => err.message?.includes('Token'))
      ) {
        return apiClient
          .post(apiUrls.refresh, {
            refresh: Cookies.get('refreshToken'),
          })
          .then(res => {
            setAccessToken(res.data.access);
          })
          .then(() => apiClient(error.config))
          .catch(() => {
            removeTokens();
            history.replace(routes.login);
          });
      }
    } else {
      toast('Network error', toastSettings);
    }

    return Promise.reject(error);
  },
);

export { apiClient };
