import React from 'react';
import styled from 'styled-components';

import { colors } from 'variables';

import dropdownArrow from 'assets/icons/dropdown.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const SelectField = styled.select`
  background: url(${dropdownArrow}) no-repeat calc(100% - 20px) center;
  -moz-appearance: none;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.primary};
  box-sizing: border-box;
  color: ${colors.primary};
`;

const SelectOption = styled.option`
  color: ${colors.primary};
  background-color: ${colors.background};
`;

const ErrorMessage = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

interface Option {
  label: string;
  value: string;
}

interface Props {
  label: string;
  name: string;
  error?: string;
  value: string;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  options: Option[];
  touched?: boolean;
  initialOption?: string;
}

export const Select: React.FC<Props> = ({
  error,
  initialOption,
  label,
  name,
  onChange,
  options,
  touched,
  value,
}) => (
  <Wrapper>
    <Label htmlFor={name}>{label}</Label>
    <SelectField id={name} name={name} value={value} onChange={onChange}>
      {initialOption && (
        <SelectOption value="" disabled>
          {initialOption}
        </SelectOption>
      )}
      {options.map(option => (
        <SelectOption key={option.value} value={option.value}>
          {option.label}
        </SelectOption>
      ))}
    </SelectField>
    {touched && error && <ErrorMessage>{error}</ErrorMessage>}
  </Wrapper>
);
