import React from 'react';
import styled from 'styled-components';
import { ParticipantCategory } from 'types';
import { colors } from 'variables';

const Wrapper = styled.div`
  flex: 0 0 120px;
  padding-right: 8px;
  font-size: 0.9rem;
  color: ${colors.secondary};
`;

interface Props {
  category: ParticipantCategory;
}

export const CategoryCell: React.FC<Props> = ({ category }) => (
  <Wrapper>{getParticipantCategory(category)}</Wrapper>
);

export const getParticipantCategory = (category: ParticipantCategory): string => {
  switch (category) {
    case ParticipantCategory.IndividualStudent:
      return 'Individual student';
    case ParticipantCategory.TeamOfStudents:
      return 'Team of students';
    case ParticipantCategory.IndividualProfessional:
      return 'Individual professional';
    case ParticipantCategory.CompanyGreaterThan10Employees:
      return 'Company > 10 employees';
    case ParticipantCategory.CompanyGreater11100Employees:
      return 'Company 11 - 100 employees';
    case ParticipantCategory.CompanyGreaterThan100Employees:
      return 'Company 100+ employees';
  }
};
