import { toast } from 'react-toastify';
import { colors } from 'variables';

export const toastSettings = {
  position: toast.POSITION.BOTTOM_RIGHT,
  hideProgressBar: true,
  style: {
    fontFamily: 'Associate Mono',
    backgroundColor: colors.primary,
    color: colors.background,
    borderRadius: 0,
  },
};
