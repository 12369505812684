import React from 'react';
import styled from 'styled-components';

import { colors } from 'variables';

import eyeIcon from 'assets/icons/eye.svg';
import eyeOffIcon from 'assets/icons/eye-off.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
`;

const InputFieldWrapper = styled.div`
  position: relative;
  width: 100%;

  img {
    position: absolute;
    top: 7px;
    right: 12px;
    cursor: pointer;
  }
`;

const InputField = styled.input`
  width: 100%;
  padding: 8px 12px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  border: 1px solid ${colors.primary};
  box-sizing: border-box;
  background: transparent;
  color: ${colors.primary};
`;

const ErrorMessage = styled.p`
  margin: 4px 0 0;
  font-size: 12px;
  line-height: 15px;
  color: ${colors.error};
`;

interface Props {
  type?: string;
  label: string;
  name: string;
  error?: string;
  touched?: boolean;
  value: string;
  onChange: (e: React.ChangeEvent<unknown>) => void;
  max?: number;
}

export const Input: React.FC<Props> = ({
  error,
  label,
  name,
  onChange,
  touched,
  value,
  type,
  max = 250,
}) => {
  const [currType, setCurrType] = React.useState(type);
  return (
    <Wrapper>
      <Label htmlFor={name}>{label}</Label>
      <InputFieldWrapper>
        <InputField
          id={name}
          type={currType}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={max}
        />
        {type === 'password' && (
          <img
            onClick={() => setCurrType(currType === 'password' ? 'text' : 'password')}
            src={currType === 'password' ? eyeOffIcon : eyeIcon}
          />
        )}
      </InputFieldWrapper>

      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  );
};
