import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 0 0 52px;
  display: flex;
  justify-content: center;
`;

interface Props {
  id: number;
}

export const prettifyProjectId = (id: number): string => id.toString().padStart(3, '0');

export const IdCell: React.FC<Props> = ({ id }) => <Wrapper>{prettifyProjectId(id)}</Wrapper>;
