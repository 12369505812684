import React from 'react';
import styled from 'styled-components';
import { colors } from 'variables';

const Wrapper = styled.div`
  flex: 0 0 55px;
  display: flex;
  justify-content: center;
  color: ${colors.secondary};
`;

interface Props {
  votes: number;
}

export const VotesCell: React.FC<Props> = ({ votes }) => <Wrapper>{votes}</Wrapper>;
