import React from 'react';
import styled from 'styled-components';
import { colors } from 'variables';

const Wrapper = styled.div`
  flex: 0 0 100px;
  padding-right: 8px;
  font-size: 0.9rem;
`;

const NotCommentedText = styled.span`
  color: ${colors.secondary};
`;

const CommentedText = styled.span`
  color: ${colors.success};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

interface Props {
  commented: number | null;
  openCommentsModal: () => void;
}

export const CommentCell: React.FC<Props> = ({ commented, openCommentsModal }) => (
  <Wrapper>
    {commented ? (
      <CommentedText onClick={openCommentsModal}>({commented}) Commented</CommentedText>
    ) : (
      <NotCommentedText>No comments</NotCommentedText>
    )}
  </Wrapper>
);
