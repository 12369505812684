import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { TextArea } from 'components/form/TextArea';
import { ImageUploadBig } from 'components/form/ImageUploadBig';

import { Input } from 'components/form/Input';
import { Select } from 'components/form/Select';
import { CheckboxGroup } from 'components/form/CheckboxGroup';
import { PrimaryButton } from 'components/common/Buttons';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { apiClient } from 'helpers/APIClient';
import { apiUrls } from 'variables';
import { useHistory } from 'react-router-dom';

const Title = styled.h3`
  margin: 0 0 32px;
`;

const Wrapper = styled.main`
  padding-bottom: 46px;
`;

const CategoryTitle = styled(Title)`
  margin-top: 16px;
`;

export enum ParticipantCategory {
  IndividualStudent = 'INDIVIDUAL_STUDENT',
  TeamOfStudents = 'TEAM_OF_STUDENTS',
  IndividualProfessional = 'INDIVIDUAL_PROFESSIONAL',
  CompanyGreaterThan10Employees = 'COMPANY_GREATER_THAN_10_EMPLOYEES',
  CompanyGreater11To100Employees = 'COMPANY_GREATER_11_100_EMPLOYEES',
  CompanyGreaterThan100Employees = 'COMPANY_GREATER_THAN_100_EMPLOYEES',
}

export enum ProjectCategory {
  ArchitecturalDesign = 'ARCHITECTURAL_DESIGN',
  ProductDesign = 'PRODUCT_DESIGN',
  UniversalDesign = 'UNIVERSAL_DESIGN',
  ResponsiveDesign = 'RESPONSIVE_DESIGN',
}

export const initialValues = {
  name: '',
  email: '',
  company: '',
  participantCategory: '',
  projectCategory: [] as string[],
  companyName: '',
  website: '',
  country: '',
  designTeam: '',
  projectName: '',
  client: '',
  location: '',
  description: '',
  image1: '',
  credits1: '',
  image2: '',
  credits2: '',
  image3: '',
  credits3: '',
  image4: '',
  credits4: '',
  image5: '',
  credits5: '',
  projectEdition: '',
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required field'),
  email: Yup.string().email('Invalid email').required('Required field'),
  participantCategory: Yup.string().required('Required field'),
  projectCategory: Yup.array().min(1, 'Please select at least one option'),
  companyName: Yup.string().required('Required field'),
  designTeam: Yup.string().required('Required field'),
  projectName: Yup.string().required('Required field'),
  description: Yup.string().required('Required field'),
  country: Yup.string().required('Required field'),
  image1: Yup.string().required('Featured Image is required'),
  projectEdition: Yup.string().required('Required field'),
});

export const categoryOptions = [
  {
    label: 'Individual student',
    value: ParticipantCategory.IndividualStudent,
  },
  {
    label: 'Team of Students',
    value: ParticipantCategory.TeamOfStudents,
  },
  {
    label: 'Individual professional',
    value: ParticipantCategory.IndividualProfessional,
  },
  {
    label: 'Company > 10 employees',
    value: ParticipantCategory.CompanyGreaterThan10Employees,
  },
  {
    label: 'Company 11 - 100 employees',
    value: ParticipantCategory.CompanyGreater11To100Employees,
  },
  {
    label: 'Company 100+ employees',
    value: ParticipantCategory.CompanyGreaterThan100Employees,
  },
];

export const projectCategoryCheckboxes = [
  {
    label: 'Architectural design',
    value: ProjectCategory.ArchitecturalDesign,
  },
  {
    label: 'Product design',
    value: ProjectCategory.ProductDesign,
  },
  {
    label: 'Universal design',
    value: ProjectCategory.UniversalDesign,
  },
  {
    label: 'Responsive design',
    value: ProjectCategory.ResponsiveDesign,
  },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatValues = (values: any, filterEmpty: boolean) => {
  const projects = Array.from(Array(5).keys())
    .map(key => key + 1)
    .reduce(
      (acc: unknown[], curr) =>
        values[`image${curr}`] === ''
          ? acc
          : [...acc, { image: values[`image${curr}`], credits: values[`credits${curr}`] || '' }],
      [],
    );

  const assignedValues = {
    fullName: values.name,
    email: values.email,
    company: values.company,
    companyName: values.companyName,
    country: values.country,
    participantCategory: values.participantCategory,
    awardCategory: values.projectCategory,
    website: values.website,
    projectName: values.projectName,
    client: values.client,
    location: values.location,
    description: values.description,
    design_team: values.designTeam,
    project_edition: values.projectEdition,
  };

  const filteredValues = Object.fromEntries(
    Object.entries(assignedValues)
      .filter(([_key, value]) => {
        if (!filterEmpty) {
          return true;
        }
        return value;
      })
      .map(([key, value]) => {
        const newValue = value === '' ? null : value;
        return [key, newValue];
      }),
  );

  return {
    ...filteredValues,
    ...(values.charged_amount && { charged_amount: Math.ceil(values.charged_amount) }),
    images: projects,
    projects,
  };
};

export const AddProject: React.FC = () => {
  const [isFetching, setIsFetching] = React.useState(false);
  const history = useHistory();

  return (
    <Wrapper>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount
        onSubmit={() => undefined}
      >
        {({ values, handleChange, errors, touched, setFieldError, setFieldValue }) => {
          const submitProject = async () => {
            setIsFetching(true);

            const body = { ...formatValues(values, false), status: 'SUBMITTED', isDeleted: false };

            try {
              await apiClient.post(apiUrls.submitProject, body).then(res => res.data);

              history.push('/projects');
            } catch {
              toast('Failed to add project', toastSettings);
            } finally {
              setIsFetching(false);
            }
          };

          return (
            <>
              <Title>Contact details</Title>

              <Input
                label="Full name"
                name="name"
                value={values.name}
                onChange={handleChange}
                error={errors.name}
                touched={touched.name}
              />

              <Input
                type="email"
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={errors.email}
                touched={touched.email}
              />

              <Input
                label="Company (optional)"
                name="company"
                value={values.company}
                onChange={handleChange}
              />

              <Select
                label="Participant category"
                name="participantCategory"
                value={values.participantCategory}
                onChange={handleChange}
                options={categoryOptions}
                initialOption="Select category"
                error={errors.participantCategory}
                touched={touched.participantCategory}
              />

              <CategoryTitle>Project category</CategoryTitle>

              <CheckboxGroup
                checkboxes={projectCategoryCheckboxes}
                name="projectCategory"
                onChange={handleChange}
                error={errors.projectCategory as string}
                touched={touched.projectCategory}
              />

              <Title>Team information</Title>

              <Input
                label="Company name"
                name="companyName"
                value={values.companyName}
                onChange={handleChange}
                error={errors.companyName}
                touched={touched.companyName}
              />
              <Input
                label="Website (optional)"
                name="website"
                value={values.website}
                onChange={handleChange}
                error={errors.website}
                touched={touched.website}
              />
              <Input
                label="Country"
                name="country"
                value={values.country}
                onChange={handleChange}
                error={errors.country}
                touched={touched.country}
              />
              <TextArea
                label="Design team"
                name="designTeam"
                value={values.designTeam}
                onChange={handleChange}
                error={errors.designTeam}
                touched={touched.designTeam}
              />

              <Title>Project details</Title>

              <Input
                label="Project name"
                name="projectName"
                value={values.projectName}
                onChange={handleChange}
                error={errors.projectName}
                touched={touched.projectName}
              />
              <Input
                label="Client (optional)"
                name="client"
                value={values.client}
                onChange={handleChange}
                error={errors.client}
                touched={touched.client}
              />
              <Input
                label="Location (optional)"
                name="location"
                value={values.location}
                onChange={handleChange}
                error={errors.location}
                touched={touched.location}
              />
              <TextArea
                label="Project description"
                name="description"
                value={values.description}
                onChange={handleChange}
                error={errors.description}
                touched={touched.description}
              />

              <Title>Project images</Title>

              <ImageUploadBig
                setFieldValue={setFieldValue}
                name="image1"
                label="Entry featured image (will present in main promotional elements)"
                error={errors.image1}
                setFieldError={setFieldError}
                value={values.image1}
              />
              <Input
                label="Credits"
                name="credits1"
                value={values.credits1}
                onChange={handleChange}
                error={errors.credits1}
                touched={touched.credits1}
              />

              <ImageUploadBig
                setFieldValue={setFieldValue}
                name="image2"
                label="Entry image 2"
                setFieldError={setFieldError}
                value={values.image2}
              />
              <Input
                label="Credits"
                name="credits2"
                value={values.credits2}
                onChange={handleChange}
                error={errors.credits2}
                touched={touched.credits2}
              />
              <ImageUploadBig
                setFieldValue={setFieldValue}
                name="image3"
                label="Entry image 3"
                setFieldError={setFieldError}
                value={values.image3}
              />
              <Input
                label="Credits"
                name="credits3"
                value={values.credits3}
                onChange={handleChange}
                error={errors.credits3}
                touched={touched.credits3}
              />
              <ImageUploadBig
                setFieldValue={setFieldValue}
                name="image4"
                label="Entry image 4"
                setFieldError={setFieldError}
                value={values.image4}
              />
              <Input
                label="Credits"
                name="credits4"
                value={values.credits4}
                onChange={handleChange}
                error={errors.credits4}
                touched={touched.credits4}
              />
              <ImageUploadBig
                setFieldValue={setFieldValue}
                name="image5"
                label="Entry image 5"
                setFieldError={setFieldError}
                value={values.image5}
              />
              <Input
                label="Credits"
                name="credits5"
                value={values.credits5}
                onChange={handleChange}
                error={errors.credits5}
                touched={touched.credits5}
              />

              <Input
                label="Project Edition"
                name="projectEdition"
                value={values.projectEdition}
                onChange={handleChange}
                error={errors.projectEdition}
                touched={touched.projectEdition}
              />

              <PrimaryButton disabled={isFetching} onClick={submitProject}>
                Add project
                {isFetching && (
                  <span>
                    <i className="fa fa-circle-o-notch fa-spin" />
                  </span>
                )}
              </PrimaryButton>
            </>
          );
        }}
      </Formik>
    </Wrapper>
  );
};
