import { apiClient } from 'helpers/APIClient';
import { toastSettings } from 'helpers/toastSettings';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { apiBase, colors } from 'variables';

const Wrapper = styled.div`
  flex: 0 0 125px;
  padding-right: 8px;
  font-size: 0.9rem;

  :hover {
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const NotRecommendedText = styled.span`
  color: ${colors.secondary};
`;

const RecommendedText = styled.span`
  color: ${colors.success};
`;

interface Props {
  deaScore: boolean;
  refresh: () => Promise<void>;
  projectId: number;
}

export const DeaScoreCell: React.FC<Props> = ({ deaScore, refresh, projectId }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const onClick = async () => {
    setIsFetching(true);

    try {
      await apiClient.put(`${apiBase}admin_panel/projects/${projectId}/dea_score/`);
      await refresh();
    } catch {
      toast('DEA score failed', toastSettings);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Wrapper onClick={onClick}>
      {deaScore ? (
        <RecommendedText>
          Recommended
          {isFetching && (
            <span>
              {' '}
              <i className="fa fa-circle-o-notch fa-spin" />
            </span>
          )}
        </RecommendedText>
      ) : (
        <NotRecommendedText>
          DEA score
          {isFetching && (
            <span>
              {' '}
              <i className="fa fa-circle-o-notch fa-spin" />
            </span>
          )}
        </NotRecommendedText>
      )}
    </Wrapper>
  );
};
