import React from 'react';
import styled from 'styled-components';
import { awardCategories, colors, mediaBase } from 'variables';
import 'rc-rate/assets/index.css';

import mockImage from 'assets/images/jurymock.jpeg';
import { JuryMember } from 'types';
import { JuryDetailsModal } from 'components/modals/JuryDetailsModal';
import { DeleteModal } from 'components/modals/DeleteModal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 150px;
  padding: 8px;

  :hover {
    background-color: ${colors.background};

    button {
      visibility: initial;
    }
  }
`;

const PrimaryText = styled.p`
  margin: 2px 0 0;
`;

const SecondaryText = styled.p`
  color: ${colors.secondary};
  margin-top: 8px;
`;

const JuryImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 8px;
`;

const DetailsButton = styled.button`
  margin-top: auto;
  visibility: hidden;
  width: 100%;
  color: white;
  background-color: ${colors.background};
  border: 1px solid ${colors.secondary};
  padding: 12px 0;
  cursor: pointer;
  transition: background-color 0.1s linear;

  :hover {
    color: black;
    background-color: white;
  }
`;

interface Props {
  jury: JuryMember;
  refetchJury: () => Promise<void>;
}

export const JuryCard: React.FC<Props> = ({ jury, refetchJury }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);

  return (
    <Wrapper>
      <JuryImage src={jury.avatar ? `${mediaBase}${jury.avatar}` : mockImage} />
      <PrimaryText>{jury.fullName}</PrimaryText>
      <SecondaryText>
        {jury.juryAssignedProjectCategories
          .map(category => awardCategories.find(awardCategory => awardCategory.key === category)?.label)
          .join(', ')}
      </SecondaryText>
      <DetailsButton onClick={() => setIsModalOpen(true)}>More details</DetailsButton>

      <JuryDetailsModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        jury={jury}
        openDeleteModal={() => setIsDeleteModalOpen(true)}
        refetchJury={refetchJury}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        closeModal={() => setIsDeleteModalOpen(false)}
        juryId={jury.uid}
        name={jury.fullName}
        refetchJury={refetchJury}
      />
    </Wrapper>
  );
};
