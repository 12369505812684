import { JuryCard } from 'components/jury/JuryCard';
import React from 'react';

import styled from 'styled-components';
import { apiBase } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { JuryDetailsModal } from 'components/modals/JuryDetailsModal';
import { apiClient } from 'helpers/APIClient';
import { JuryMember } from 'types';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { Loader } from 'components/common/Loader';

const HeaderSection = styled.div`
  width: 100%;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
`;

const JuryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 166px);
`;

const Button = styled(PrimaryButton)`
  margin-top: auto;
  margin-bottom: 16px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  font-size: 20px;
  padding: 12px;
  width: 380px;
`;

export const JuryList: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [jury, setJury] = React.useState<JuryMember[]>([]);
  const [isFetching, setIsFetching] = React.useState(false);

  const refetchJury = () => apiClient.get(`${apiBase}jury/`).then(({ data }) => setJury(data));

  React.useEffect(() => {
    setIsFetching(true);

    apiClient
      .get(`${apiBase}jury/`)
      .then(({ data }) => setJury(data))
      .then(() => setIsFetching(false))
      .catch(() => toast('Failed to fetch jury', toastSettings));
  }, []);

  if (isFetching) return <Loader />;

  return (
    <>
      <HeaderSection>
        <Button onClick={() => setIsModalOpen(true)}>Add new jury</Button>
      </HeaderSection>

      <JuryGrid>
        {jury.map(j => (
          <JuryCard key={j.uid} jury={j} refetchJury={refetchJury} />
        ))}
      </JuryGrid>
      <JuryDetailsModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        openDeleteModal={() => null}
        refetchJury={refetchJury}
      />
    </>
  );
};
