import React from 'react';
import styled from 'styled-components';
import { colors } from 'variables';

const Wrapper = styled.div`
  flex: 0 0 370px;
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

const Company = styled.p`
  font-size: 0.9rem;
  margin-top: -8px;
  color: ${colors.secondary};
  word-break: break-word;
`;

const Image = styled.img`
  min-width: 110px;
  max-width: 110px;
  height: 80px;
  object-fit: cover;
  margin-right: 12px;
`;

interface Props {
  image: string;
  name: string;
  company: string | null;
  email: string | null;
}

export const ProjectCell: React.FC<Props> = ({ image, name, company, email }) => (
  <Wrapper>
    <Image src={image} />
    <div>
      <p>{name}</p>
      <Company>{company || '-'}</Company>
      <Company>
        <u>{email || '-'}</u>
      </Company>
    </div>
  </Wrapper>
);
