import React from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { JuryList } from 'pages/jury/JuryList';
import { mediaQueries, colors, routes } from 'variables';
import { ProjectTable } from 'pages/projects';

const Wrapper = styled.main`
  /* padding: 0 24px; */
  width: 100%;

  ${mediaQueries.lg} {
    padding: 36px 0;
  }
`;

const HeaderSection = styled.div`
  width: 100%;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
`;

const Category = styled.span<{ active: boolean }>`
  font-size: 18px;
  cursor: pointer;
  color: ${props => (props.active ? 'white' : colors.secondary)};

  :not(:last-child) {
    margin-right: 32px;
  }
`;

const categories = [
  { label: 'Jury', path: '/jury' },
  { label: 'Projects', path: '/projects' },
];

export const AppInnerWrapper: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <Wrapper>
      <HeaderSection>
        {categories.map(category => (
          <Category
            key={category.label}
            active={location.pathname === category.path}
            onClick={() => history.push(category.path)}
          >
            {category.label}
          </Category>
        ))}
      </HeaderSection>
      <Route path={routes.projects} exact>
        <ProjectTable />
      </Route>

      <Route path={routes.jury} exact>
        <JuryList />
      </Route>
    </Wrapper>
  );
};
