import { AwardCategory } from 'types';

const mediaQ = (size: number) => `@media only screen and (min-width: ${size}px)`;

export const colors = {
  background: '#202020',
  primary: '#FFFFFF',
  secondary: '#8B8B8B',
  error: '#DB3832',
  success: '#44a12b',
};

export const mediaQueries = {
  xs: mediaQ(320),
  sm: mediaQ(576),
  md: mediaQ(850),
  lg: mediaQ(992),
  xl: mediaQ(1200),
  xxl: mediaQ(1440),
};

export const projectRegex = /project\d+$/;

export const mediaBase = process.env.REACT_APP_MEDIA_URL;
export const apiBase = process.env.REACT_APP_API_URL;

export const awardCategories = [
  { key: AwardCategory.ArchitecturalDesign, label: 'Architectural design' },
  { key: AwardCategory.ProductDesign, label: 'Product design' },
  { key: AwardCategory.ResponsiveDesign, label: 'Responsive design' },
  { key: AwardCategory.UniversalDesign, label: 'Universal design' },
];

export const apiUrls = {
  login: `${apiBase}admin_panel/token/`,
  profileDetails: `${apiBase}profiles/show-details/`,
  uploadJuryImage: `${apiBase}jury/avatar/`,
  refresh: `${apiBase}token/refresh/`,
  submitProject: `${apiBase}admin_panel/projects/`,
  projectComments: (projectId: number): string =>
    `${apiBase}admin_panel/projects/${projectId}/comments/`,
};

export const routes = {
  login: '/login',
  jury: '/jury',
  projects: '/projects',
};
