import React from 'react';
import styled from 'styled-components';
import starIcon from 'assets/icons/star.svg';
import { colors } from 'variables';

const Wrapper = styled.div`
  flex: 0 0 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.secondary};
  font-size: 1.2rem;
`;

const Total = styled.span`
  margin-top: 8px;
`;

interface Props {
  total: number | null;
}

export const TotalCell: React.FC<Props> = ({ total }) => (
  <Wrapper>
    <img src={starIcon} />
    <Total>{total || 0}</Total>
  </Wrapper>
);
