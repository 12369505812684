import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';
import { Formik } from 'formik';

import { apiBase, colors } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { ImageUpload } from 'components/form/ImageUpload';
import { Input } from 'components/form/Input';
import { TextArea } from 'components/form/TextArea';
import { CheckboxGroup } from 'components/form/CheckboxGroup';
import { AwardCategory, JuryMember } from 'types';
import { apiClient } from 'helpers/APIClient';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 340px;
    padding: 16px;
    padding-top: 8px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: ${colors.background};
    color: ${colors.secondary};
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;
    height: 80%;
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 4px;
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  margin-left: auto;
  background-color: transparent;
  color: ${colors.secondary};
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const Button = styled(PrimaryButton)`
  background-color: transparent;
  color: white;
  border: 2px solid white;

  padding: 12px;

  width: 100%;
  margin-right: 6px;
`;

const DeleteButton = styled(Button)`
  border: none;
  color: ${colors.secondary};
  margin-right: 0;
  margin-left: 6px;

  :hover {
    color: white;
  }
`;

const Box = styled.div`
  display: flex;
  margin-top: 16px;
`;

const Form = styled.form`
  overflow-y: scroll;
`;

const HeaderText = styled.p`
  color: white;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 12px;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  jury?: JuryMember;
  openDeleteModal: () => void;
  refetchJury: () => Promise<void>;
}

export const projectCategoryCheckboxes = [
  {
    label: 'Architectural design',
    value: AwardCategory.ArchitecturalDesign,
  },
  {
    label: 'Product design',
    value: AwardCategory.ProductDesign,
  },
  {
    label: 'Universal design',
    value: AwardCategory.UniversalDesign,
  },
  {
    label: 'Responsive design',
    value: AwardCategory.ResponsiveDesign,
  },
];

interface FormPayload {
  avatarUrl: string;
  fullName: string;
  companyName: string;
  email: string;
  password: string;
  biogram: string;
  awardCategories: AwardCategory[];
}

export const JuryDetailsModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  jury,
  openDeleteModal,
  refetchJury,
}) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const initialValues = jury
    ? {
        avatarUrl: jury.avatar || '',
        fullName: jury.fullName,
        companyName: jury.juryCompanyName || '',
        email: jury.email,
        password: '',
        biogram: jury.bio || '',
        awardCategories: jury.juryAssignedProjectCategories,
      }
    : {
        avatarUrl: '',
        fullName: '',
        companyName: '',
        email: '',
        password: '',
        biogram: '',
        awardCategories: [],
      };

  const saveJury = async (formData: FormPayload) => {
    setIsFetching(true);

    try {
      jury
        ? await apiClient.put(`${apiBase}jury/${jury.uid}/`, {
            ...formData,
            juryAssignedProjectCategories: formData.awardCategories,
          })
        : await apiClient.post(`${apiBase}jury/`, {
            ...formData,
          });

      await refetchJury();
      closeModal();
    } catch {
      toast('Saving jury failed', toastSettings);
    } finally {
      setIsFetching(false);
    }
  };

  return (
    <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
      <ModalStyles />
      <Header>
        <HeaderText>{jury ? 'Edit jury details' : 'Adding new jury'}</HeaderText>
        <CloseButton onClick={closeModal}>X</CloseButton>
      </Header>

      <Formik onSubmit={() => undefined} initialValues={initialValues}>
        {({ setFieldError, setFieldValue, values, handleChange }) => (
          <Form>
            <ImageUpload
              label="Avatar"
              name="avatarUrl"
              setFieldError={setFieldError}
              setFieldValue={setFieldValue}
              value={values.avatarUrl}
            />

            <Input label="Full name" name="fullName" value={values.fullName} onChange={handleChange} />
            <Input
              label="Company name"
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
            />
            <Input label="Email" name="email" value={values.email} onChange={handleChange} />
            {!jury && (
              <Input
                label="Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                type="password"
              />
            )}

            <TextArea label="Biogram" name="biogram" value={values.biogram} onChange={handleChange} />

            <div style={{ marginBottom: '32px' }}>
              <Label>Award categories</Label>
            </div>

            <CheckboxGroup
              checkboxes={projectCategoryCheckboxes}
              name="awardCategories"
              onChange={handleChange}
            />

            <Box>
              <Button type="button" onClick={() => saveJury(values)}>
                Save
                {isFetching && (
                  <span>
                    <i className="fa fa-circle-o-notch fa-spin" />
                  </span>
                )}
              </Button>
              {jury && (
                <>
                  <DeleteButton
                    type="button"
                    onClick={() => {
                      openDeleteModal();
                      closeModal();
                    }}
                  >
                    Delete
                  </DeleteButton>
                </>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};
