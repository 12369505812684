import * as Yup from 'yup';

export interface LoginRequestValues {
  email: string;
  password: string;
}

export const initialValues = {
  email: '',
  password: '',
};

export const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required field'),
  password: Yup.string().required('Required field'),
});
