import React from 'react';
import styled from 'styled-components';

export const LoaderWrapper = styled.div`
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
`;

export const LoaderInnerWrapper = styled.div`
  width: 100%;
  text-align: center;
  font-size: 56px;
`;

export const Loader = (): JSX.Element => (
  <LoaderWrapper>
    <LoaderInnerWrapper>
      <i className="fa fa-circle-o-notch fa-spin" />
    </LoaderInnerWrapper>
  </LoaderWrapper>
);
