import React from 'react';
import { Redirect } from 'react-router-dom';

import { logout, setUserData, AuthContext } from 'contexts/AuthContext';
import { apiClient } from 'helpers/APIClient';
import { routes, apiUrls } from 'variables';

const LoggedIn: React.FC = ({ children }) => {
  const {
    state: { isAuth },
    dispatch,
  } = React.useContext(AuthContext);
  const [loggedIn, setLoggedIn] = React.useState(false);

  React.useEffect(() => {
    if (isAuth) {
      apiClient
        .get(apiUrls.profileDetails)
        .then(response => {
          dispatch(setUserData(response.data));
          setLoggedIn(true);
        })
        .catch(() => {
          dispatch(logout());
        });
    }
  }, [isAuth, dispatch]);

  return isAuth ? <>{loggedIn && children}</> : <Redirect to={routes.login} />;
};

export default LoggedIn;
