import React from 'react';

import Select, { StylesConfig } from 'react-select';
import { colors } from 'variables';

interface Option {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  label: string;
}

interface Props {
  options: Option[];
  value: Option | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: any;
}

const customStyles: StylesConfig = {
  control: provided => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    justifyContent: 'flex-end',
  }),
  valueContainer: provided => ({ ...provided, paddingLeft: 0 }),
  placeholder: provided => ({ ...provided }),
  singleValue: provided => ({ ...provided, color: colors.secondary }),
  indicatorSeparator: () => ({ display: 'none' }),
  indicatorsContainer: provided => ({
    ...provided,
    padding: '0 !important',
    '*': {
      padding: '0 !important',
    },
    svg: {
      color: colors.secondary,
    },
  }),
  menu: provided => ({ ...provided }),
  menuList: provided => ({
    ...provided,
    backgroundColor: '#58585A',
    padding: '10px',
  }),
  option: (provided, state) => ({
    ...provided,
    textDecoration: state.isSelected ? 'underline' : 'none',
    color: state.isSelected ? 'white' : colors.secondary,
    backgroundColor: 'transparent',

    ':hover': {
      backgroundColor: colors.background,
    },
  }),
};

const StyledSelect = ({ options, value, onChange }: Props): JSX.Element => (
  <Select
    styles={customStyles}
    options={options}
    defaultValue={options[0]}
    isSearchable={false}
    controlShouldRenderValue={false}
    placeholder={value?.label}
    value={value}
    onChange={onChange}
  />
);

export default StyledSelect;
