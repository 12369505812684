import React from 'react';
import Modal from 'react-modal';
import styled, { createGlobalStyle } from 'styled-components';

import { apiBase, colors } from 'variables';
import { PrimaryButton } from 'components/common/Buttons';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { apiClient } from 'helpers/APIClient';

const ModalStyles = createGlobalStyle`
  .Modal {
    display: flex;
    flex-direction: column;
    margin: 16px;
    width: 100%;
    max-width: 300px;
    padding: 16px;
    padding-top: 8px;
    border: none;
    box-shadow: 0 0 17px 1px rgba(0, 0, 0, 0.32);
    background-color: ${colors.background};
    color: ${colors.secondary};
    outline: none;
    text-align: left;
    font-family: 'Associate Mono';
    box-sizing: border-box;
  }

  .Overlay {
    z-index: 999;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Header = styled.div`
  display: flex;
  padding-bottom: 4px;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  border: none;
  font-family: 'Associate Mono Bold';
  width: 20px;
  font-size: 20px;
  margin-left: auto;
  background-color: transparent;
  color: ${colors.secondary};
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const Button = styled(PrimaryButton)`
  background-color: transparent;
  color: white;
  border: 2px solid white;

  padding: 12px;

  width: 100%;
  margin-left: 6px;
`;

const DeleteButton = styled(Button)`
  border: none;
  color: ${colors.secondary};
  margin-right: 0;
  margin-left: 6px;

  :hover {
    color: white;
  }
`;

const Paragraph = styled.p`
  color: white;
  text-align: center;
`;

const Box = styled.div`
  display: flex;
  margin-top: 16px;
`;

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  juryId: string;
  name: string;
  refetchJury: () => Promise<void>;
}

export const DeleteModal: React.FC<Props> = ({ isOpen, closeModal, juryId, name, refetchJury }) => {
  const [isFetching, setIsFetching] = React.useState(false);

  const deleteJury = async () => {
    setIsFetching(true);

    try {
      await apiClient.delete(`${apiBase}jury/${juryId}/`);
      await refetchJury();
      closeModal();
    } catch {
      toast('Failed to delete jury', toastSettings);
      setIsFetching(false);
    }
  };

  return (
    <Modal isOpen={isOpen} className="Modal" overlayClassName="Overlay" onRequestClose={closeModal}>
      <ModalStyles />
      <Header>
        <CloseButton onClick={closeModal}>X</CloseButton>
      </Header>

      <Paragraph>Are you sure you want to delete {name}?</Paragraph>

      <Box>
        <DeleteButton type="button" onClick={closeModal}>
          Discard
        </DeleteButton>

        <Button type="button" onClick={deleteJury}>
          Delete
          {isFetching && (
            <span>
              <i className="fa fa-circle-o-notch fa-spin" />
            </span>
          )}
        </Button>
      </Box>
    </Modal>
  );
};
